import classNames from 'classnames';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './BasicPage.module.scss';

const BasicPageContainer = ({ noBorder, noBackground, children, className, style, headChildren, classes = {} }) => {
  return (
    <Container className={classes.container}>
      {headChildren}
      <Row className={classes.row}>
        <Col className={classes.col}>
          <div
            className={classNames(styles['basic-page-container'], className, {
              [styles['no-border']]: noBorder,
              [styles['background']]: !noBackground,
            })}
            style={style}
          >
            {children}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export { BasicPageContainer as default, BasicPageContainer };
