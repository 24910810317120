import { SVG } from './SVG';
const IconLogin = ({ height = '26', width = '25' }) => (
  <SVG width={width} height={height} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.0852 10.5629C19.1031 9.54503 19.1031 7.89468 18.0852 6.87677C17.0672 5.85887 15.4169 5.85887 14.399 6.87677C13.3811 7.89468 13.3811 9.54503 14.399 10.5629C15.4169 11.5808 17.0672 11.5808 18.0852 10.5629Z"
      fill="#4062BB"
    />
    <path
      d="M8.93504 10.9708L9.64215 11.6779L10.0701 11.25L9.88947 10.6723L8.93504 10.9708ZM1 18.9058L0.292894 18.1987L4.79532e-07 18.4916L4.48476e-07 18.9058L1 18.9058ZM1 24.0926L5.96046e-08 24.0926L-1.53697e-08 25.0926H1V24.0926ZM7.2306 24.0926V25.0926H8.2306L8.2306 24.0926L7.2306 24.0926ZM7.2306 21.5481V20.5481H6.2306L6.2306 21.5481L7.2306 21.5481ZM9.75872 21.5481V22.5481H10.7587L10.7587 21.5481L9.75872 21.5481ZM9.75872 18.9874V17.9874H8.75872L8.75872 18.9874L9.75872 18.9874ZM12.4336 18.9874V19.9874H13.4336V18.9874H12.4336ZM12.4336 16.2635L12.4302 15.2635L11.4336 15.267V16.2635H12.4336ZM21.7143 3.24779L22.4214 2.54068L22.4195 2.53876L21.7143 3.24779ZM10.1281 2.54068C7.76751 4.90127 7.05008 8.29339 7.98061 11.2692L9.88947 10.6723C9.17264 8.37991 9.72743 5.76978 11.5423 3.95489L10.1281 2.54068ZM8.22793 10.2637L0.292894 18.1987L1.70711 19.6129L9.64215 11.6779L8.22793 10.2637ZM4.48476e-07 18.9058L5.96046e-08 24.0926L2 24.0926L2 18.9058L4.48476e-07 18.9058ZM1 25.0926H7.2306V23.0926H1V25.0926ZM8.2306 24.0926L8.2306 21.5481L6.2306 21.5481L6.2306 24.0926L8.2306 24.0926ZM7.2306 22.5481H9.75872V20.5481H7.2306V22.5481ZM10.7587 21.5481L10.7587 18.9874L8.75872 18.9874L8.75872 21.5481L10.7587 21.5481ZM9.75872 19.9874H12.4336V17.9874H9.75872V19.9874ZM13.4336 18.9874V16.2635H11.4336V18.9874H13.4336ZM12.4371 17.2635C12.9927 17.2616 13.6129 17.3126 14.4047 17.3506C15.1649 17.387 16.0229 17.4064 16.9168 17.3223C18.7233 17.1524 20.6988 16.5566 22.4214 14.834L21.0072 13.4198C19.7042 14.7228 18.2178 15.1912 16.7295 15.3311C15.9761 15.402 15.2285 15.3878 14.5005 15.3529C13.8039 15.3195 13.053 15.2614 12.4302 15.2635L12.4371 17.2635ZM22.4214 14.834C25.813 11.4423 25.813 5.93233 22.4214 2.54068L21.0072 3.95489C23.6178 6.56549 23.6178 10.8092 21.0072 13.4198L22.4214 14.834ZM22.4195 2.53876C19.0219 -0.840428 13.5215 -0.852715 10.1281 2.54068L11.5423 3.95489C14.1512 1.34604 18.3881 1.35006 21.0091 3.95681L22.4195 2.53876Z"
      fill="#4062BB"
    />
  </SVG>
);
export { IconLogin as default, IconLogin };
