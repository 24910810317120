/* eslint-disable react/display-name */
import classNames from 'classnames';
import { What } from '@medifind/shared-basic-components';
import styles from './BasicPage.module.scss';

const BasicPageHeader = ({ children, icon, title, what, className, id, noBorder, div }) => {
  if ((icon || title || what || div) && !children) {
    children = (
      <>
        <div className={styles['title__wrapper']}>
          {icon && <div className={styles['icon']}>{icon}</div>}
          {title && (
            <h2 id={id} className={styles['title']}>
              {title}
            </h2>
          )}
          {what && (
            <div className={styles['hint']}>
              <What>{what}</What>
            </div>
          )}
        </div>
        {div && div}
      </>
    );
  }
  return <div className={classNames(styles['header'], className, { [styles['no-border']]: noBorder })}>{children}</div>;
};

export { BasicPageHeader as default, BasicPageHeader };
