import { BasicPageBody, BasicPageContainer, BasicPageHeader } from '@medifind/containers';
import { LoginError, LoginForm } from '@medifind/forms';
import { IconLogin } from '@medifind/icons';
import { useRouter } from '@medifind/router';
import { usePageHooks } from '@medifind/router';
import { SEO } from '@medifind/seo';
import { Button } from '@medifind/shared-basic-components';
import { useAuthRedirect } from '@medifind/zustand';
import styles from './auth.module.scss';

/* __ROUTE_PATH__=/login */
const Login = (props) => {
  const { loginError } = useAuthRedirect();
  const { errorMessage, errorTitle } = loginError ?? {};
  usePageHooks();
  const router = useRouter();
  const signup = (router.query && router.query.signup === '1') || null;

  return (
    <>
      <SEO
        title="Login | MediFind"
        description="Login or Signup to find and track the latest information about medical conditions and the top medical doctors for those conditions."
      />
      <BasicPageContainer>
        <BasicPageHeader icon={<IconLogin />} title="Login" id="login-page-title" />
        <BasicPageBody>
          {(errorMessage || errorTitle) && <LoginError errorMessage={errorMessage} errorTitle={errorTitle} />}
          <LoginForm showForgetPassword={true} {...{ props, signup }} />
          <Button
            classes={{ container: styles['auth-switch-button'] }}
            label={
              <>
                New to MediFind? <b>Create Account</b>
              </>
            }
            color="light-blue"
            type="thin"
            onClick={() => router.history.push('/register')}
          />
          <Button
            classes={{ container: styles['claim-profile-link'] }}
            label={
              <>
                Are you a provider? <b>Claim Profile</b>
              </>
            }
            onClick={() => router.history.push('/profile-management/sign-up')}
            type="thin"
            color="light-blue"
          />
        </BasicPageBody>
      </BasicPageContainer>
    </>
  );
};

export default Login;
