import { IconBookmarkSmall, IconDoubleNext } from '@medifind/icons';
import styles from './LoginError.module.scss';

const LoginError = ({ errorMessage, errorTitle, icon }) => {
  return (
    <div className={styles['error-message']}>
      {errorTitle ? (
        <h4>
          {icon === 'save' ? <IconBookmarkSmall /> : icon === 'next' ? <IconDoubleNext /> : ''} {errorTitle}
        </h4>
      ) : null}
      {errorMessage}
    </div>
  );
};

export { LoginError as default, LoginError };
