import PropTypes from 'prop-types';
import { colorLookup } from '@medifind/utils';
import { SVG } from './SVG';
const IconBookmarkSmall = ({ color, fill, width = 12, height = 15 }) => {
  const colorCode = colorLookup(color);
  let fillCode = 'none';
  if (fill) {
    fillCode = colorCode;
  }
  return (
    <SVG width={width} height={height} viewBox="0 0 12 15" fill={fillCode} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1111 14L6.05556 10.3889L1 14V2.44444C1 1.6467 1.6467 1 2.44444 1H9.66667C10.4644 1 11.1111 1.6467 11.1111 2.44444V14Z"
        stroke={colorCode}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};
IconBookmarkSmall.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};
export { IconBookmarkSmall as default, IconBookmarkSmall };
