import classNames from 'classnames';
import cssStyles from './BasicPage.module.scss';

const BasicPageBody = ({ children, noPadding, className, topRow, classes = {}, styles = {}, titleAndFilter, xsNoPadding }) => (
  <div
    className={classNames(cssStyles['body'], className, {
      [cssStyles['body--no-padding']]: noPadding,
      [cssStyles['body--xs--no-padding']]: xsNoPadding,
    })}
    style={styles.container}
  >
    {topRow && (
      <div
        className={classNames(cssStyles['top-row'], classes.topRow, {
          [cssStyles['top-row-titleAndFilter']]: titleAndFilter,
        })}
        style={styles.topRow}
      >
        {topRow}
      </div>
    )}
    {children}
  </div>
);

export { BasicPageBody as default, BasicPageBody };
