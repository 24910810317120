import { colorLookup } from '@medifind/utils';
import { SVG } from './SVG';
const IconDoubleNext = ({ width = 20, height = 20, color }) => {
  const colorCode = colorLookup(color) ?? colorLookup('blue');
  return (
    <SVG height={height} width={width} fill={colorCode} viewBox="0 0 98 98" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M82.7,46.9L56.4,20.6c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2L76.4,49L52.2,73.2c-1.2,1.2-1.2,3.1,0,4.2   c0.6,0.6,1.4,0.9,2.1,0.9s1.5-0.3,2.1-0.9l26.3-26.3C83.9,50,83.9,48.1,82.7,46.9z"></path>
        <path d="M19.5,20.6c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2L39.5,49L15.3,73.2c-1.2,1.2-1.2,3.1,0,4.2   c0.6,0.6,1.4,0.9,2.1,0.9c0.8,0,1.5-0.3,2.1-0.9l26.3-26.3c0.6-0.6,0.9-1.3,0.9-2.1s-0.3-1.6-0.9-2.1L19.5,20.6z"></path>
      </g>
    </SVG>
  );
};

export { IconDoubleNext as default, IconDoubleNext };
